<template>
  <CarruselRedesSocialesSkeleton v-if="isServer" />
  <Flicking
    v-if="!isServer"
    :options="options"
    :plugins="pluginsCarruselPrimero"
    class="sm:hidden max-w-96 mx-auto"
    ref="carruselRedesSocialesPrimero"
  >
    <TarjetaRedesSociales
      v-for="(tarjeta, index) in redes"
      :key="index"
      :red="tarjeta"
      class="mb-20 mx-3"
    />
    <template #viewport>
      <Paginador
        class="bottom-1 lg:bottom-5"
        :carrusel="carruselRedesSocialesSegundo"
        :nombre-paginador="nombrePaginadorCarruselRedesSocialesPrimero"
        :background="false"
      />
    </template>
  </Flicking>

  <Flicking
    v-if="!isServer"
    :options="options"
    :plugins="pluginsCarruselSegundo"
    class="hidden sm:block lg:hidden"
    ref="carruselRedesSocialesSegundo"
  >
    <div
      class="flex mb-20 gap-x-6 justify-center h-auto w-full"
      v-for="(agrupado, index) in redesAgrupadas"
      :class="{ 'mr-6': index != redesAgrupadas.length - 1 }"
      :key="index"
    >
      <TarjetaRedesSociales :red="agrupado[0]" class="w-1/2" />
      <TarjetaRedesSociales :red="agrupado[1]" class="w-1/2" />
    </div>
    <template #viewport>
      <Paginador
        class="bottom-1 lg:bottom-5"
        :carrusel="carruselRedesSocialesSegundo"
        :nombre-paginador="nombrePaginadorCarruselRedesSocialesSegundo"
        :background="false"
      />
    </template>
  </Flicking>

  <div class="hidden lg:flex lg:justify-between gap-x-6">
    <TarjetaRedesSociales
      v-for="(tarjeta, index) in redes"
      :key="index"
      :red="tarjeta"
      :class="[`w-1/${redes.length}`]"
    />
  </div>
</template>
<script setup lang="ts">
  import TarjetaRedesSociales from "@components/TarjetaRedesSociales.vue";
  import Flicking from "@egjs/vue3-flicking";
  import Paginador from "@ui/Paginador.vue";
  import "@egjs/flicking-plugins/dist/pagination.css";
  import { getConfig } from "@api/client/configuraciones";
  import CarruselRedesSocialesSkeleton from "@components/skeletons/CarruselRedesSocialesSkeleton.vue";
  import { ref, onMounted, computed } from "@vue/runtime-core";
  import type { RedSocial } from "@lib/interfaces";
  import { plugins } from "@lib/bullets";

  interface Props {
    panels?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    panels: -1,
  });

  const options = {
    align: "center",
    panelsPerView: props.panels,
    noPanelStyleOverride: false,
    preventDefaultOnDrag: true,
  };

  const isServer = ref<boolean>(true);
  const redes = ref<RedSocial[]>([]);

  const redesAgrupadas = computed(() => {
    const redesAgrupadas = [];
    for (let i = 0; i < redes.value.length; i += 2) {
      redesAgrupadas.push([redes.value[i], redes.value[i + 1]]);
    }
    return redesAgrupadas;
  });
  const carruselRedesSocialesSegundo = ref<Flicking>();
  const carruselRedesSocialesPrimero = ref<Flicking>();

  const nombrePaginadorCarruselRedesSocialesPrimero =
    "paginador-carrusel-redes-sociales-primero";
  const nombrePaginadorCarruselRedesSocialesSegundo =
    "paginador-carrusel-redes-sociales-segundo";
  const pluginsCarruselPrimero = plugins(
    nombrePaginadorCarruselRedesSocialesPrimero,
    false,
  );
  const pluginsCarruselSegundo = plugins(
    nombrePaginadorCarruselRedesSocialesSegundo,
    false,
  );

  onMounted(async () => {
    const response = await getConfig<RedSocial[]>("redesSociales");
    if (response.estado == "ok" && response.config.data) {
      redes.value = response.config.data;
    }
    isServer.value = false;
  });
</script>
