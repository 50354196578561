import type { ResultadoGetConfig } from "@lib/types";

const baseUrl = import.meta.env.PUBLIC_API_URL;

export const getConfig = async <T>(
  param: string,
): Promise<ResultadoGetConfig<T>> => {
  try {
    const response = await fetch(baseUrl + "getConfig", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        parametro: param,
      }),
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      console.error(errorResponse);
      throw new Error(response.statusText);
    }
    const json = await response.json();
    const configuracion = json.config;

    return {
      estado: "ok",
      config: configuracion,
    };
  } catch (error) {
    console.error(error);
    return {
      estado: "error",
      config: null,
    };
  }
};
