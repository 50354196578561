<template>
  <div
    class="rounded-lg drop-shadow-md bg-white flex justify-center p-4 pb-20 relative cursor-pointer transition-transform sm:pb-12 lg:pb-16 max-w-96"
    @click="redireccionar"
  >
    <div class="relative h-fit">
      <picture>
        <source :srcset="`${props.red.imagen}.webp`" type="image/webp" />
        <img
          :src="`${props.red.imagen}.jpg`"
          alt="Servicio"
          class="aspect-square"
        />
      </picture>
      <div
        class="absolute flex -bottom-10 inset-x-0 mx-auto rounded-full bg-black h-20 w-20 justify-center items-center p-5 sm:h-12 sm:w-12 md:w-14 md:h-14 sm:-bottom-6 lg:-bottom-8 lg:h-16 lg:w-16"
      >
        <a
          :href="props.red.link"
          target="_blank"
          :aria-label="props.red.aria_label"
          class="flex"
        >
          <component
            :is="iconosRedes[props.red.icon]"
            fill="fill-white"
            class="sm:h-6 sm:w-6 md:w-7 md:h-7 lg:h-9 lg:w-9"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TikTokIcon from "@components/iconos/TikTokIcon.vue";
  import FacebookFooterIcon from "@components/iconos/FacebookFooterIcon.vue";
  import InstagramFooterIcon from "@components/iconos/InstagramFooterIcon.vue";
  import LinkedInIcon from "@components/iconos/LinkedInIcon.vue";
  import type { RedSocial } from "@lib/interfaces";
  import type { Component } from "vue";

  interface Props {
    red: RedSocial;
  }
  const props = defineProps<Props>();

  const iconosRedes: Record<string, Component> = {
    FacebookFooterIcon: FacebookFooterIcon,
    InstagramFooterIcon: InstagramFooterIcon,
    TikTokIcon: TikTokIcon,
    LinkedInIcon: LinkedInIcon,
  };

  const redireccionar = () => {
    window.open(props.red.link, "_blank");
  };
</script>
