<template>
  <div class="animate-pulse flex justify-center gap-x-5">
    <div
      v-for="_ in total"
      class="rounded-lg drop-shadow-md p-4 bg-gray-50 pb-20 sm:pb-12 lg:pb-16 hidden first:flex sm:flex h-full"
    >
      <div
        class="bg-gray-100 h-52 w-52 sm:h-28 sm:w-28 md:w-36 md:h-36 lg:w-48 lg:h-48 xl:w-64 xl:h-64 relative"
      >
        <div
          class="absolute flex -bottom-10 inset-x-0 mx-auto rounded-full bg-black h-20 w-20 justify-center items-center p-5 sm:h-12 sm:w-12 sm:-bottom-6 lg:-bottom-8 lg:h-16 lg:w-16"
        >
          <div class="sm:h-6 sm:w-6 lg:h-9 lg:w-9" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  interface Props {
    total?: number;
  }
  withDefaults(defineProps<Props>(), {
    total: 4,
  });
</script>
